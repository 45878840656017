import * as THREE from 'three';
import { collection, addDoc, doc, updateDoc, deleteDoc, getDoc, documentId } from 'firebase/firestore';
import { useFirestore } from 'vuefire';
import { OrbitControlsEventMap, OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
// import Stats from 'three/examples/jsm/libs/stats.module.js';

import * as ttUtils from './ttUtils';
import * as ttCmd from './ttCommands';
import ttGlbLoader from './ttGlbLoader';
import { Polyline } from './ttObject';
import { ttMetashapeData, ttMetashapeParse, ttMetashapeSensor } from './ttMetashapeParse';
import { lineMaterialModel, lineMaterialImage } from './ttMaterials';

import { ViewHelper } from 'three/examples/jsm/helpers/ViewHelper.js';

import { markRaw, reactive, ref } from 'vue';
import { useCurrentUser, useFirebaseAuth } from 'vuefire';


function pointToPointDist(x1: number, y1: number, z1: number, x2: number, y2: number, z2: number): number {
    return Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)) + ((z2 - z1) * (z2 - z1)));
}

const trajectoryPointsCacheStep = 1.0;
function closestPointToTrajectory(trajectory: THREE.Curve<THREE.Vector3>, point: THREE.Vector3, closestPointSta: THREE.Vector4 | null): THREE.Vector3 {
    if (!trajectory.pointsCache || trajectory.pointsCache.length === 0) {
        trajectory.pointsCache = trajectory.getPoints(Math.ceil(trajectory.getLength() / trajectoryPointsCacheStep));
    }
    const closestPt = trajectory.pointsCache[0].clone();
    let minDist = point.distanceTo(closestPt);
    let minIndex = 0;
    for (let i = 1; i < trajectory.pointsCache.length; i++) {
        const currentDist = trajectory.pointsCache[i].distanceTo(point);
        if (currentDist < minDist) {
            minDist = currentDist;
            closestPt.copy(trajectory.pointsCache[i]);
            minIndex = i;
        }
    }
    if (closestPointSta) {
        closestPointSta.set(closestPt.x, closestPt.y, closestPt.z, Math.min(trajectory.getLength(), minIndex * trajectoryPointsCacheStep));
    }
    return closestPt;
}

function ttScene() {
    THREE.Object3D.DefaultUp.set(0, 1, 0);
    const scene = new THREE.Scene();
    const ambientLight = new THREE.AmbientLight(0xffffff, 1)
    ambientLight.name = "ambient_light";
    scene.add(ambientLight)
    return scene;
}

function createMDIIconElement(viewer: Viewer, line: Polyline, iconName: string, x: number, y: number): HTMLElement {
    const icon = document.createElement('i');
    icon.className = `mdi ${iconName}`;
    icon.style.position = 'absolute';
    icon.style.fontSize = '24px';
    icon.style.color = `#${line.getColor().toString(16).padStart(6, '0')}`;
    icon.style.textShadow = `0px 0px 1px ${ttUtils.contrastColor(line.getColor())}`;
    icon.style.cursor = 'pointer';
    icon.style.left = "0px";
    icon.style.top = "0px";
    icon.style.pointerEvents = "auto";
    icon.style.transition = 'transform';
    icon.custom_x = x;
    icon.custom_y = y;
    icon.custom_line = line;
    icon.style.transform = `translate(${x - 12}px, ${y - 12}px) scale(1)`;

    icon.addEventListener('mouseenter', () => {
        icon.style.transform = `translate(${icon.custom_x - 12}px, ${icon.custom_y - 12}px) scale(1.25)`;
    });

    icon.addEventListener('mouseleave', () => {
        icon.style.transform = `translate(${icon.custom_x - 12}px, ${icon.custom_y - 12}px) scale(1.0)`;
    });

    icon.addEventListener('click', () => {
        if (viewer.selection.has(icon.custom_line)) {
            viewer.selection.remove(icon.custom_line);
        }
        else {
            viewer.selection.clear();
            viewer.selection.add(icon.custom_line);
        }
    });

    return icon;
}

function createSVGElement(viewer: Viewer, line: Polyline) {
    const element = document.getElementById("mainCanvasDivId");
    if (!element) {
        console.log("No mainCanvasDivId");
        return;
    }
    const width = element.clientWidth;
    const height = element.clientHeight;
    const v = new THREE.Vector3();
    line.vertexAt(0, v);
    v.project(viewer.camera);
    const x = (v.x * 0.5 + 0.5) * width;
    const y = (-v.y * 0.5 + 0.5) * height;
    const svg = createMDIIconElement(viewer, line, "mdi-map-marker", x, y);
    line.svg = svg;
    element.appendChild(svg);
}

function createSVGElements(viewer: Viewer) {
    const v = new THREE.Vector3();
    const v2 = new THREE.Vector3();
    const element = document.getElementById("mainCanvasDivId");
    if (!element) {
        console.log("No mainCanvasDivId");
        return;
    }

    const width = element.clientWidth;
    const height = element.clientHeight;
    for (const line of viewer.lines) {
        if (line.count() !== 2)
            continue;
        line.vertexAt(0, v);
        line.vertexAt(1, v2);
        if (v.distanceToSquared(v2) > 0.01)
            continue;
        v.project(viewer.camera);
        const x = (v.x * 0.5 + 0.5) * width;
        const y = (-v.y * 0.5 + 0.5) * height;
        const svg = createMDIIconElement(viewer, line, "mdi-map-marker", 0xffffff, x, y);
        line.svg = svg;
        element.appendChild(svg);
    }
}

function ttCreateTrajectory(viewer: Viewer, metashapeData: ttMetashapeData, minDist = 0.75, maxDist = 1.5) {
    const tfx = new THREE.Matrix4();
    const cameraVec = new THREE.Vector3();
    const path = [];
    for (let msCamera of metashapeData.cameras) {
        let add = true;
        tfx.copy(metashapeData.transform);
        tfx.multiply(msCamera.transform);
        cameraVec.setFromMatrixPosition(tfx);
        if (path.length > 0 && path[path.length - 1].distanceTo(cameraVec) > maxDist) {
            add = false;
        }
        else {
            for (let i = 0; i < path.length; i++) {
                if (path[i].distanceTo(cameraVec) < minDist) {
                    add = false;
                    break;
                }
            }
        }
        if (add) {
            path.push(new THREE.Vector3(cameraVec.x, cameraVec.y, cameraVec.z));
        }
    }
    path.reverse();

    const geometry = new THREE.BufferGeometry().setFromPoints(path);
    const material = new THREE.LineBasicMaterial( { color: 0xff0000 } );
    const trajectoryLine = new THREE.Line( geometry, material );
    trajectoryLine.name = "trajectory";
    viewer.trajectory.points = path;
    if (!viewer.trajectory.pointsCache || viewer.trajectory.pointsCache.length === 0) {
        viewer.trajectory.pointsCache = viewer.trajectory.getPoints(Math.ceil(viewer.trajectory.getLength() / viewer.trajectoryPointsCacheStep));
    }
    viewer.scene.add(trajectoryLine);
}

async function ttCreateCamerasAndSetViewport(viewer: Viewer) {
    const metashapeData = await ttMetashapeParse(viewer.projectName);
    viewer.metashapeData = metashapeData;
    const tfx = new THREE.Matrix4();
    const cameraVec = new THREE.Vector3();
    tfx.copy(metashapeData.transform);
    tfx.multiply(metashapeData.cameras[0].transform);
    if (viewer.camera.position.equals(new THREE.Vector3(0, 0, 0))) {
        viewer.camera.position.setFromMatrixPosition(tfx);
        const lookAtVec = new THREE.Vector3(0, 0, 1);
        lookAtVec.applyMatrix4(tfx);
        viewer.controls.target.copy(lookAtVec);
    }
    const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
    const geometry = new THREE.BoxGeometry(0.12, 0.12, 0.12);
    const cameraSensorId = metashapeData.cameras[0].sensorId;
    for (let msCamera of metashapeData.cameras) {
        const cameraGeom = new THREE.Mesh(geometry, material);
        tfx.copy(metashapeData.transform);
        tfx.multiply(msCamera.transform);
        cameraVec.setFromMatrixPosition(tfx);
        cameraGeom.up.set(0, 1, 0);
        cameraGeom.applyMatrix4(tfx);
        viewer.msCameras.push(cameraGeom);
        cameraGeom.msSensorId = msCamera.sensorId;
        cameraGeom.msLabel = msCamera.label;
        cameraGeom.msComponentId = msCamera.componentId;
        viewer.scene.add(cameraGeom);
        cameraGeom.visible = false;
    }
    ttCreateTrajectory(viewer, metashapeData);
}

function projectLinesOnImage(viewer: Viewer, cameraIdx: number) {
    if (!viewer.imageCanvas || !viewer.metashapeData)
        return;
    for (let drawnImageLine of viewer.drawnLinesImage) {
        viewer.imageScene.remove(drawnImageLine);
    }
    viewer.drawnLinesImage = [];

    const camera = viewer.metashapeData.cameras[cameraIdx];

    const tfx = new THREE.Matrix4();
    tfx.copy(viewer.metashapeData.transform);
    tfx.multiply(camera.transform);
    tfx.invert();
    const sensor = viewer.metashapeData.sensors.find(e => e.id === camera.sensorId);
    if (!sensor)
        return;
    for (const line of viewer.lines) {
        const geometry = ttUtils.projectLineOnImage(line, tfx, sensor, true);
        if (geometry) {
            const newLine = new LineSegments2(geometry, lineMaterialImage[line.severity]);
            newLine.computeLineDistances();
            newLine.renderOrder = 1;
            viewer.imageScene.add(newLine);
            viewer.drawnLinesImage.push(newLine);
        }
    }
}

function mainCanvasClickHandler(viewer: Viewer, event: MouseEvent) {
    if (viewer.currentCommand.value) //TODO: remove listener
        return;
    if (!viewer.metashapeData)
        return;
    const mouse = ttUtils.toNormalizedScreenCoord(event.clientX, event.clientY, viewer.canvas);
    viewer.raycaster.setFromCamera(mouse, viewer.camera);
    const intersects = viewer.raycaster.intersectObjects(viewer.scene.children, true);
    for (const intersection of intersects) {
        for (const line of viewer.lines) {
            if (line.obj == intersection.object) {
                if (viewer.selection.has(line)) {
                    viewer.selection.remove(line);
                }
                else {
                    viewer.selection.add(line);
                }
                break;
            }
        }
    }
}

function mainCanvasDblClickHandler(viewer: Viewer, event: MouseEvent) {
    if (viewer.currentCommand.value) //TODO: remove listener
        return;
    if (!viewer.metashapeData)
        return;
    const intersObj = ttUtils.raycastPointOnMainModel(viewer, event);
    if (intersObj === null)
        return;
    const intersection = intersObj.point;
    const viewDir = new THREE.Vector3(0, 0, 1);
    const intersToPos = new THREE.Vector3();
    viewDir.subVectors(viewer.camera.position, viewer.controls.target).normalize();
    intersToPos.subVectors(intersection, viewer.camera.position);
    const projLen = intersToPos.dot(viewDir);
    viewer.controls.target.copy(intersection);
    const newCameraPos = new THREE.Vector3(projLen * viewDir.x, projLen * viewDir.y, projLen * viewDir.z);
    newCameraPos.subVectors(intersection, newCameraPos);
    viewer.camera.position.copy(newCameraPos);
    let minLen = 1e100;
    let minIdx = -1;
    for (let i = 0; i < viewer.msCameras.length; i++) {
        let cameraPos = new THREE.Vector3();
        let cameraDir = viewer.msCameras[i].getWorldDirection(new THREE.Vector3(0, 0, 1));
        let cameraToInters = new THREE.Vector3();
        cameraPos.copy(viewer.msCameras[i].position);
        cameraDir.normalize();
        cameraToInters.subVectors(intersection, cameraPos);

        let projectedDistance = cameraDir.dot(cameraToInters);
        if (projectedDistance < 0.0)
            continue;
        cameraDir.multiplyScalar(projectedDistance);
        cameraDir.add(cameraPos);
        cameraDir.sub(intersection);
        let cl = cameraDir.length();
        if (minLen > cl) {
            minLen = cl;
            minIdx = i;
        }
    }
    viewer.cameraIndex = minIdx;
    viewer.imageLabel.value = viewer.metashapeData.cameras[minIdx].label;
    const loader = new THREE.TextureLoader();
    const material = new THREE.MeshLambertMaterial({
        map: loader.load(`/models/${viewer.projectName}/cameras/${viewer.imageLabel.value}.JPG`)
    });
    if (viewer.imageMaterial) {
        viewer.imageMaterial.map = material.map;
    }
    else {
        viewer.imageMaterial = material;
    }
    projectLinesOnImage(viewer, minIdx);
}

function imageCanvasDblClickHandler(viewer: Viewer, event: MouseEvent) {
}

class UndoStack {
    undo: ttCmd.CmdBase[] = reactive([]);
    redo: ttCmd.CmdBase[] = reactive([]);
    max:  number    = 50;
}

export class Selection {
    viewer: Viewer;
    objects: Polyline[] = reactive([]);

    constructor(v: Viewer) {
        this.viewer = v;
    }

    add(line: Polyline) {
        if (this.has(line))
            return;
        this.objects.push(line);
        line.setMaterial(lineMaterialModel.length - 1);
    }

    addRange(start: number, end: number) {
        const s = Math.min(start, end);
        if (s < 0)
            return;
        const e = Math.min(Math.max(start, end), this.viewer.lines.length - 1);
        for (let i = s; i <= e; i++) {
            this.add(this.viewer.lines[i]);
        }
    }

    remove(line: Polyline) {
        const index = this.objects.indexOf(line);
        if (index > -1) {
            this.objects.splice(index, 1);
            line.setMaterial(line.severity);
        }
    }

    clear() {
        for (const line of this.objects) {
            line.setMaterial(line.severity);
        }
        this.objects.length = 0;
    }

    has(line: Polyline): boolean {
        return this.objects.includes(line);
    }

    clone(): Selection {
        const res = new Selection(this.viewer);
        for (const o of this.objects)
            res.add(o);
        return res;
    }
}

export class Settings {
    name: string = 'ttSettings';
    data: object = {
        'cameras_visible': false,
        'trajectory_visible': true,
    };

    constructor() {
        if (window.localStorage[name] === undefined) {
            window.localStorage[name] = JSON.stringify(this.data);
        } else {
            const data = JSON.parse(window.localStorage[name]);
            for (const key in data) {
                this.data[key] = data[key];
            }
        }
    }

    get(key: string) {
        return this.data[key];
    }

    set(key: string, val: any) {
        this.data[key] = val;
        window.localStorage[this.name] = JSON.stringify(this.data);
    }
}

export class Viewer {
    renderer:          THREE.WebGLRenderer | null = null;
    imageRenderer:     THREE.WebGLRenderer | null = null;
    canvas:            HTMLElement | null = null;
    imageCanvas:       HTMLElement | null = null;
    imageMesh:         THREE.Mesh = new THREE.Mesh();
    msCameras:         THREE.Mesh[] = [];
    camera:            THREE.PerspectiveCamera = new THREE.PerspectiveCamera();
    orthoCamera:       THREE.OrthographicCamera = new THREE.OrthographicCamera();
    controls?:         OrbitControls;
    orthoControls?:    OrbitControls;
    scene:             THREE.Scene = ttScene();
    imageScene:        THREE.Scene = ttScene();
    metashapeData:     ttMetashapeData | null = null;
    cameraIndex:       number = -1;
    raycaster:         THREE.Raycaster = new THREE.Raycaster;
    models:            THREE.Object3D[] = [];
    trajectory:        THREE.CatmullRomCurve3 = new THREE.CatmullRomCurve3();
    trajectoryPos      = ref<THREE.Vector4>(new THREE.Vector4());

    currentCommand     = ref<ttCmd.CmdBase | null>(null);

    lines:             Polyline[] = reactive([]);
    drawnLinesImage:   LineSegments2[] = []; //TODO: combine with Polyline?

    imageLabel         = ref<string>("Default.JPG");
    imageMaterial:     THREE.MeshLambertMaterial | null = null;

    currentSeverity:   number = 1;

    viewHelper:        ViewHelper;
    undoStack:         UndoStack = new UndoStack();
    settings:          Settings = new Settings();
    selection:         Selection = new Selection(this);

    projectName:       string = '';

    //TODO: remove
    // stats: Stats = new Stats();

    constructor(user: User, projectName: string) {
        this.projectName = projectName;
    }

    async afterMount() {
        if (this.metashapeData)
            return;
        //setup viewports from Metashape
        await Promise.all([
            ttCreateCamerasAndSetViewport(this),
            ttGlbLoader(this.projectName, 'model0.glb').then(objs => {
                if (!objs) {
                    console.error("Loading glbs failed");
                    return;
                }
                for (var o of objs) {
                    if (o) {
                        viewer?.addModel(o);
                    }
                }
            }),
            this.loadServer(),
        ]);
    }

    onUnmounted() {
        document.removeEventListener("keydown", this.keyboardHandler);

        if (this.canvas) {
            this.canvas.removeEventListener("click", mainCanvasClickHandler(this, event));
            this.canvas.removeEventListener("dblclick", mainCanvasDblClickHandler(this, event));
        }

        if (this.imageCanvas) {
            this.imageCanvas.removeEventListener("dblclick", imageCanvasDblClickHandler(this, event));
        }
    }

    beginCommand(command: ttCmd.CmdBase) {
        if (this.currentCommand.value) {
            console.log('command already running:' + this.currentCommand.value);
            return;
        }
        this.undoStack.redo.length = 0;
        this.currentCommand.value = command;
        this.currentCommand.value.onBegin();
    }

    endCommand() {
        if (this.currentCommand.value)
            this.currentCommand.value.onEnd();
        this.undoStack.undo.push(this.currentCommand.value);
        if (this.undoStack.undo.length > this.undoStack.max)
            this.undoStack.undo.slice(0, 1);
        this.currentCommand.value = null;
    }

    cancelCommand() {
        if (this.currentCommand.value)
            this.currentCommand.value.onCancel();
        this.currentCommand.value = null;
    }

    undo() {
        if (this.undoStack.undo.length === 0)
            return;
        const cmd = this.undoStack.undo.pop();
        if (cmd) {
            this.undoStack.redo.push(cmd);
            cmd.undo();
        }
    }

    redo() {
        if (this.undoStack.redo.length === 0)
            return;
        const cmd = this.undoStack.redo.pop();
        if (cmd) {
            this.undoStack.undo.push(cmd);
            cmd.redo();
        }
    }

    addModel(model: THREE.Object3D) {
        this.models.push(model);
        this.scene.add(model);
    }

    removeModel(model: THREE.Object3D) {
        this.models = this.models.filter((e: THREE.Object3D) => e !== model);
        this.scene.remove(model);
    }

    addLine(line: Polyline) {
        if (this.lines.includes(line)) {
            return;
        }
        if (line.count() === 0) {
            console.log("Empty line");
        }
        this.scene.add(line.obj);
        if (line.severity < 0) {
            line.severity = this.currentSeverity;
        }
        line.setMaterial(line.severity);
        this.lines.push(line);
        if (line.name.length === 0) {
            line.name = "Line - " + line.obj.id;
        }
        if (line.count() === 2) {
            const a = new THREE.Vector3();
            const b = new THREE.Vector3();
            line.vertexAt(0, a);
            line.vertexAt(0, b);
            if (a.distanceToSquared(b) < 0.01) {
                createSVGElement(this, line);
            }
        }
    }

    removeLine(line: Polyline) {
        const index = this.lines.indexOf(line);
        if (index > -1) {
            this.lines.splice(index, 1);
            this.scene.remove(line.obj);
        }
        if (line.svg) {
            const element = document.getElementById("mainCanvasDivId");
            if (element) {
                element.removeChild(line.svg);
                line.svg = null;
            }
        }
    }

    updateOnControlChange() {
        if (!this.controls)
            return;
        const element = document.getElementById("mainCanvasDivId");
        if (!element) {
            console.log("No mainCanvasDivId");
            return;
        }
        let wtm = new THREE.Matrix4();
        const v = new THREE.Vector3();
        const vt = new THREE.Vector3();
        const width = element.clientWidth;
        const height = element.clientHeight;
        this.camera.updateMatrix();
        this.camera.updateProjectionMatrix();
        this.camera.updateMatrixWorld();
        wtm.copy(this.camera.matrixWorld);
        wtm = wtm.invert();

        closestPointToTrajectory(this.trajectory, this.camera.position, this.trajectoryPos.value);

        //Move svg - markers
        for (const line of this.lines) {
            if (line.svg) {
                line.vertexAt(0, v);
                vt.set(v.x, v.y, v.z);
                vt.applyMatrix4(wtm);
                v.project(this.camera);
                if (vt.z < 0.0) {
                    const x = (v.x * 0.5 + 0.5) * width;
                    const y = (-v.y * 0.5 + 0.5) * height;
                    line.svg.style.transform = `translate(${x - 12}px, ${y - 12}px) scale(1)`;
                    line.svg.custom_x = x;
                    line.svg.custom_y = y;
                    line.svg.style.display = "";
                }
                else {
                    line.svg.style.display = "none";
                }
            }
        }
    }


    setMainCanvas(mainCanvas: HTMLElement, renderer: THREE.WebGLRenderer) {
        this.renderer      = renderer;
        this.canvas        = mainCanvas;
        this.viewHelper    = new ViewHelper(this.camera, mainCanvas);
        this.camera.up.set(0, 0, 1);
        this.camera.aspect = mainCanvas.clientWidth / mainCanvas.clientHeight;
        this.camera.updateProjectionMatrix();
        this.canvas.addEventListener("click", (event) => mainCanvasClickHandler(this, event));
        this.canvas.addEventListener("dblclick", (event) => mainCanvasDblClickHandler(this, event));
        document.addEventListener("keydown", (event) => this.keyboardHandler(event), false);

        this.controls = new OrbitControls(this.camera, mainCanvas);
        this.controls.enableDamping = false;
        this.controls.listenToKeyEvents(window);
        this.controls.addEventListener('change', (event) => {
            this.updateOnControlChange();
        });
        //this.controls.screenSpacePanning = false;
        this.controls.keyPanSpeed = 20;
        this.controls.mouseButtons = {
            MIDDLE: THREE.MOUSE.PAN,
            RIGHT: THREE.MOUSE.ROTATE
        };
        if (this.stats) {
            document.body.append(this.stats.dom);
        }
    }

    setImageCanvas(imageCanvas: HTMLElement, renderer: THREE.WebGLRenderer) {
        if (this.imageCanvas) {
            this.imageCanvas.removeEventListener("dblclick", imageCanvasDblClickHandler(this, event));
        }
        this.imageRenderer = renderer;
        this.imageCanvas   = imageCanvas;
        if (this.imageCanvas) {
            this.imageCanvas.addEventListener("dblclick", (event) => imageCanvasDblClickHandler(this, event));
            const aspect = this.imageCanvas.clientWidth / this.imageCanvas.clientHeight;
            this.imageScene.background = new THREE.Color(0x448811);
            const imwidth = viewer?.metashapeData?.sensors[0].widthPx;
            const imheight = viewer?.metashapeData?.sensors[0].heightPx;

            this.orthoCamera.left   =  0.0;
            this.orthoCamera.right  =  imwidth;
            this.orthoCamera.top    =  imheight;
            this.orthoCamera.bottom =  0.0;
            this.orthoCamera.near   = -1000.0;
            this.orthoCamera.far    =  1000.0;
            this.orthoCamera.position.set(0.5 * imwidth, 0.5 * imheight, 1.0);
            this.orthoCamera.up.set(0, 1, 0);
            this.orthoCamera.updateProjectionMatrix();
            this.orthoControls = new OrbitControls(this.orthoCamera, this.imageCanvas);
            this.orthoControls.target.set(0.5 * imwidth, 0.5 * imheight, 0.0);
            this.orthoControls.enableRotate = false;
            this.orthoControls.minZoom = 1.0;
            this.orthoControls.update();

            if (!this.imageMaterial) {
                const loader = new THREE.TextureLoader();
                this.imageMaterial = new THREE.MeshLambertMaterial({
                    map: loader.load(`/models/${this.projectName}/cameras/default.JPG`)
                });

                this.imageMaterial.side = THREE.DoubleSide;
            }
            else {
                projectLinesOnImage(this, this.cameraIndex);
            }
            var geometry = new THREE.PlaneGeometry(imwidth, imheight);
            this.imageMesh = new THREE.Mesh(geometry, this.imageMaterial);
            this.imageMesh.position.set(imwidth, imheight, 0.0);
            this.imageMesh.up.set(0.0, 1.0, 0.0);
            if (!this.imageScene.getObjectById(this.imageMesh.id)) {
                this.imageScene.add(this.imageMesh);
            }

        }
    }

    toJSON(): object {
        const linesStr: object[] = [];
        for (let l of this.lines) {
            if (Object.keys(l).length !== 0) {
                linesStr.push(l.toJSON());
            }
        }
        return {
            userData: {
                camera: this.camera.toJSON(),
                controlsTarget: this.controls.target.toArray(),
                lastProject: this.projectName,
            },
            data: {
                lines: linesStr
            }
        };
    }

    async fromJSON(json: object) {
        let loader = new THREE.ObjectLoader();
        let camera = await loader.parseAsync(json.userData.camera);
        if (json.controlsTarget !== undefined) {
            this.controls.target.fromArray(json.userData.controlsTarget);
        }
        this.camera.copy(camera);
        this.camera.up.set(0, 0, 1);
        this.camera.updateProjectionMatrix();
        this.controls.update();

        this.lines.length = 0;
        for (let l of json.data.lines) {
            if (Object.keys(l).length === 0)
                continue;
            let ls = new Polyline();
            ls.fromJSON(l);
            this.lines.push(ls);
            this.scene.add(ls.obj);
        }
    }

    objectByUUID(uuid: string) {
        return this.scene.getObjectByProperty('uuid', uuid);
    }

    saveLocal() {
        let jsonData = JSON.stringify(this.toJSON());
        localStorage.setItem('appData', jsonData);
    }

    async saveServer() {
        const db = useFirestore();
        const user = useCurrentUser();
        const json = this.toJSON();
        const projectDoc = doc(db, "projects", this.projectName);
        const userDoc = doc(db, "users", user.value!.email);
        const fbUserDoc = updateDoc(userDoc, json.userData);
        const fbDataDoc = updateDoc(projectDoc, { lines: json.data.lines});
        await fbUserDoc;
        await fbDataDoc;
    }

    async loadServer() {
        let loader = new THREE.ObjectLoader();
        const db = useFirestore();
        const user = useCurrentUser();
        const projectDoc = doc(db, "projects", this.projectName);
        const userDoc = doc(db, "users", user.value!.email);

        const fbu = getDoc(userDoc);
        const fbp = getDoc(projectDoc);
        const fbUserDoc = await fbu;
        if (fbUserDoc.exists()) {
            const userData = fbUserDoc.data();
            if (userData.lastProject !== undefined && userData.lastProject === this.projectName) {
                let camera = await loader.parseAsync(fbUserDoc.data().camera);
                if (fbUserDoc.data().controlsTarget !== undefined) {
                    this.controls.target.fromArray(fbUserDoc.data().controlsTarget);
                }
                this.camera.copy(camera);
                this.camera.up.set(0, 0, 1);
                this.camera.updateProjectionMatrix();
                this.controls.update();
            }
        }

        const fbDataDoc = await fbp;
        if (fbDataDoc.exists()) {
            this.lines.length = 0;
            if (fbDataDoc && fbDataDoc.data() && fbDataDoc.data().lines) {
                for (let l of fbDataDoc.data().lines) {
                    if (Object.keys(l).length === 0)
                        continue;
                    let ls = new Polyline();
                    ls.fromJSON(l);
                    this.lines.push(ls);
                    this.scene.add(ls.obj);
                    if (ls.name.length === 0) {
                        ls.name = "Line - " + ls.obj.id;
                    }
                }
            }
        }
        createSVGElements(this);
    }

    keyboardHandler(event: KeyboardEvent) {
        if (event.target && event.target.nodeName !== "BODY") {
            return true;
        }
        const keyName = event.code;
        switch (keyName) {
            case 'Escape': {
                if (this.currentCommand.value !== null) {
                    this.cancelCommand();
                }
                else {
                    this.selection.clear();
                }
                break;
            }
            case 'Enter': {
                if (this.currentCommand.value !== null) {
                    this.endCommand();
                }
                break;
            }
            case 'KeyP': {
                this.createPolylineCommand();
                break;
            }
            case 'KeyM': {
                this.createMarkerCommand();
                break;
            }
            case 'KeyD': {
                this.getDistanceCommand();
                break;
            }
            case 'KeyU': {
                this.undo();
                break;
            }
            case 'KeyR': {
                this.redo();
                break;
            }
            case 'Delete': {
                if (this.currentCommand.value === null) {
                    let command = new ttCmd.DeleteLinesCmd(this);
                    this.beginCommand(command);
                    this.endCommand();
                }
                break;
            }
        }
    }

    createPolylineCommand() {
        if (this.currentCommand.value === null) {
            let command = new ttCmd.CreatePolylineCmd(this);
            this.beginCommand(command);
        }
    }

    createMarkerCommand() {
        if (this.currentCommand.value === null) {
            let command = new ttCmd.CreateMarkerCommand(this);
            this.beginCommand(command);
        }
    }

    getDistanceCommand() {
        if (this.currentCommand.value === null) {
            let command = new ttCmd.GetDistanceCmd(this);
            this.beginCommand(command);
        }
    }

    zoomTo(object: THREE.Object3D) {
        const box = new THREE.Box3();
        box.setFromObject(object, false);
        const boxCenter = new THREE.Vector3();
        box.getCenter(boxCenter);
        const closestPointOnTrajectory = closestPointToTrajectory(this.trajectory, boxCenter, null);
        viewer?.controls?.target.set(boxCenter.x, boxCenter.y, boxCenter.z);
        viewer?.controls?.object.position.set(closestPointOnTrajectory.x, closestPointOnTrajectory.y, closestPointOnTrajectory.z);
        viewer?.controls?.update();

        if (viewer?.imageCanvas) {
        }
    }

    setStation(value: number) {
        value = Math.max(0, Math.min(this.trajectory.getLength(), value));
        const tdir0 = this.trajectory.getTangent(value / this.trajectory.getLength());
        const tdir1 = this.trajectory.getTangent(value / this.trajectory.getLength());
        const m = new THREE.Matrix4();
        m.makeBasis
        tdir0.angleTo(tdir1);
        const newPos = this.trajectory.pointsCache[Math.floor(value / trajectoryPointsCacheStep)];
        const dir = new THREE.Vector3();
        dir.subVectors(this.controls.target, this.camera.position);
        this.camera.position.copy(newPos);

        const raycaster = new THREE.Raycaster();
        raycaster.set(newPos, dir);
        const intersects = raycaster.intersectObjects(this.models, true);
        if (intersects.length) {
            this.controls?.target.copy(intersects[0].point);
        }
        else {
            this.controls?.target.copy(dir.add(newPos));
        }
    }

    importJSON(file) {
        const lengthThreshold = 0.0001;
        const v = new THREE.Vector3();
        const tfx = new THREE.Matrix4();
        const raycaster = new THREE.Raycaster();
        if (!this.metashapeData) {
            console.error("Metashape data not loaded yet!");
            return;
        }
        for (let dataI = 0; dataI < file.data.length; dataI++) {
            const data = file.data[dataI];
            const imagename: string = data.image_name.substring(0, data.image_name.length - 4);
            const camera = this.metashapeData?.cameras.find((e) => e.label === imagename);
            if (!camera) {
                console.error(`Image ${imagename} not found in metashape data!`);
                continue;
            }
            const sensor = this.metashapeData.sensors.find(e => e.id === camera.sensorId);
            if (!sensor) {
                console.error(`Camera sensor id: ${camera.sensorId} not found in metashape data!`);
                continue;
            }
            tfx.copy(this.metashapeData.transform);
            tfx.multiply(camera.transform);
            for (const line of data.lines) {
                let points : number[];
                if (line.data.length === 0) {
                    continue;
                }
                if (typeof line.data[0] === "number") {
                    points = line.data;
                }
                else {
                    points = [];
                    for (const point of line.data) {
                        points.push(point[0]);
                        points.push(point[1]);
                    }
                }
                let pLength = 0.0;
                const geomPoints: number[] = [3 * points.length / 2];
                for (let i = 0; i < points.length; i += 2) {
                    v.x = points[i];
                    v.y = sensor.heightPx - points[i + 1];
                    const xx = (v.x - sensor.widthPx / 2 - sensor.cx) / sensor.f;
                    const yy = -(v.y - sensor.heightPx / 2 + sensor.cy) / sensor.f;
                    const pos = new THREE.Vector3();
                    const point = new THREE.Vector3(xx, yy, 1.0);
                    pos.setFromMatrixPosition(tfx);
                    point.applyMatrix4(tfx);
                    const dir = new THREE.Vector3();
                    dir.subVectors(point, pos);

                    raycaster.set(pos, dir);
                    const intersects = raycaster.intersectObjects(this.models, true);
                    if (intersects.length === 0) {
                        console.error("Error unprojecting point: " + i / 2);
                        continue;
                    }

                    geomPoints[i * 3 / 2 + 0] = (intersects[0].point.x);
                    geomPoints[i * 3 / 2 + 1] = (intersects[0].point.y);
                    geomPoints[i * 3 / 2 + 2] = (intersects[0].point.z);
                    if (i > 0) {
                        const si = (i / 2 - 1) * 3;
                        pLength += pointToPointDist(geomPoints[si + 0], geomPoints[si + 1], geomPoints[si + 2],
                                                    geomPoints[si + 3], geomPoints[si + 4], geomPoints[si + 5]);
                    }
                }
                if (pLength < lengthThreshold) {
                    console.log(`Skipping line nr: ${dataI} because it's length is: ${pLength} < ${lengthThreshold}`);
                    continue;
                }
                const pline = new Polyline(geomPoints);
                if (line.meta) {
                    let { severity, Severity, ... meta } = line.meta;
                    if (severity || Severity) {
                        if (severity === undefined)
                            severity = Severity;
                        if (typeof severity === "string") {
                            severity = severity.toLowerCase();
                            if (severity === "unknown") {
                                pline.severity = 0;
                            }
                            else if (severity === "low") {
                                pline.severity = 1;
                            }
                            else if (severity === "normal" || severity === "medium") {
                                pline.severity = 2;
                            }
                            else if (severity === "high") {
                                pline.severity = 3;
                            }
                            else {
                                console.error("Unknown type of severity: ", severity);
                            }
                        }
                        else if (typeof severity === "number") {
                            pline.severity = severity;
                        }
                        else {
                            console.error("Unknown type of severity: ", severity);
                        }
                    }
                    if (meta)
                        pline.custom = meta;
                }
                this.addLine(pline);
            }
        }
    }
}

let viewer: Viewer | null = null;
export function createViewer(user: User, projectName: string): Viewer {
    viewer = new Viewer(user, projectName);
    return viewer;
}

export function getViewer(): Viewer | null {
    if (viewer)
        return viewer;
    console.error("Null viewer retrieved");
    return null;
}

export function destroyViewer() {
    if (viewer)
        viewer = null;
    else
        console.error("Viewer already null");
}
