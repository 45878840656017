<template>
    <v-app>
        <v-app-bar app density="compact">
            <v-app-bar-nav-icon icon="mdi-tunnel" :to="{ path: '/dashboard' }" />
                <v-breadcrumbs :items="[{title: 'Tunnel Twin', href: '/dashboard'}, { title: route.params.projectName }]" class="font-weight-medium"></v-breadcrumbs>
                <v-spacer></v-spacer>
                <v-slider
                        class="mr-5 pa-0"
                        v-model="trajectoryPosition"
                        :min="0.0"
                        :max="trajectoryLength"
                        :step="1.0"
                        hide-details
                        thumb-size="12"
                        density="compact">

                    <template v-slot:prepend>
                        <v-text-field
                                v-model="trajectoryPosition"
                                density="compact"
                                variant="plain"
                                class="m-0 p-0"
                                style="width: 25px"
                                hide-details
                                ></v-text-field>
                    </template>
                </v-slider>
                <v-btn icon="mdi-pencil-plus" v-if="!viewer.currentCommand.value" @click="createPolyline" density="compact" v-tooltip:bottom="'Shortcut: P'">  </v-btn>
                <v-btn icon="mdi-map-marker" v-if="!viewer.currentCommand.value" @click="createMarker" density="compact" v-tooltip:bottom="'Shortcut: M'"></v-btn>
                <v-btn icon="mdi-ruler" v-if="!viewer.currentCommand.value" @click="getDistance" density="compact" v-tooltip:bottom="'Shortcut: D'"></v-btn>
                <v-btn icon="mdi-check" v-if="viewer.currentCommand.value" @click="acceptCommand" density="compact" color="green" v-tooltip:bottom="'Shortcut: Enter'"></v-btn>
                <v-btn icon="mdi-cancel" v-if="viewer.currentCommand.value" @click="cancelCommand" density="compact" color="red" v-tooltip:bottom="'Shortcut: Escape'"></v-btn>
                <v-btn icon="mdi-delete-outline" @click="deleteSelection" :disabled="selectedLines.length === 0" density="compact" v-tooltip:bottom="'Shortcut: Delete'"> </v-btn>
                <v-btn icon="mdi-undo" :disabled="viewer.undoStack.undo.length === 0" @click="undo" density="compact" v-tooltip:bottom="'Shortcut: U'"> </v-btn>
                <v-btn icon="mdi-redo" :disabled="viewer.undoStack.redo.length === 0" @click="redo" density="compact" v-tooltip:bottom="'Shortcut: R'"> </v-btn>
                <v-btn icon="mdi-window-maximize" :active="floatingWindowVisible" @click=floatingWindowToggle density="compact"> </v-btn>
                <v-btn icon="mdi-magnify" @click="toggleSearch" density="compact"></v-btn>
                <div class="search-container">
                    <v-slide-x-transition>
                        <v-text-field
                                v-if="searchVisible"
                                v-model="searchQuery"
                                density="compact"
                                hide-details
                                single-line
                                rounded
                                clearable
                                variant="outlined"
                                placeholder="Search for keys or values..."
                                class="mx-2"
                                ref="searchInput"
                                ></v-text-field>
                    </v-slide-x-transition>
                </div>

                <v-spacer></v-spacer>
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" text>
                            {{ email }}
                            <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item title="Profile" value="Profile" @click="onProfile" density="compact" prepend-icon="mdi-account-circle"/>
                        <v-list-item title="Save" value="Save" @click="onSave" density="compact" prepend-icon="mdi-content-save"/>
                        <v-list-item title="Settings" value="Settings" @click="settingsDialogVisible = true" density="compact" prepend-icon="mdi-cog-outline"/>
                        <v-list-item title="Import" value="Import" @click="openFilePicker" density="compact" prepend-icon="mdi-file-import-outline"/>
                        <v-list-item title="Logout" value="Logout" @click="onLogout" density="compact" prepend-icon="mdi-logout"/>
                    </v-list>
                </v-menu>
            </v-app-bar>
            <v-navigation-drawer app width="240">
                <v-list dense>
                    <v-list-item-group>
                        <v-list-item
                                v-for="(line, i) in filteredLines"
                                :key="i"
                                :title="line.name"
                                @click.shift.exact="handlePropPanelClick(line, i, true, false)"
                                @click.ctrl.exact="handlePropPanelClick(line, i, false, true)"
                                @click.exact="handlePropPanelClick(line, i, false, false)"
                                density="compact"
                                :active="selectedLines.includes(line)"
                                slim
                                class="my-0 py-0"
                                style="user-select: none;"
                                >
                                <template v-slot:append>
                                    <v-btn slim class="mx-2 my-0 py-0" density="compact" icon="mdi-eye-outline" variant="text" @click.stop="handlePropPanelZoomTo(line, i)" />
                                </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <v-navigation-drawer width="320" v-if="selectedLines.length === 1">
                <v-card>
                    <v-card-text fill-height>
                        <v-text-field v-model="selectedLines[0].name" label="Name" density="compact" slim type="input" clearable></v-text-field>
                        <v-combobox v-model="severityRef" slim density="compact" label="Severity" :items="severityItems" @update:modelValue="onChangeSeverity"></v-combobox>
                        <!-- <v-autocomplete slim density="compact" label="Type" :items="['Line', 'Crack', 'Light', 'Sign']"></v-autocomplete> -->
                        <v-text-field v-model="selectedLines[0].url" 
                                      label="URL" 
                                      density="compact" 
                                      slim 
                                      type="input" 
                                      clearable
                                      append-icon="mdi-open-in-new" 
                                      @update:model-value="validateUrl"
                                      @click:append="openUrl"
                                      ></v-text-field>
                        <v-text-field v-model="selectedLines[0].createdBy" label="Created by" density="compact" slim type="input" clearable></v-text-field>
                        <v-text-field v-for="(value, key) in selectedLines[0].custom" :key="key"
                            v-model="selectedLines[0].custom[key]"
                            :label="key"
                            density="compact"
                            slim
                            clearable
                            class="my-0 py-0"
                            >
                        </v-text-field>

                        <v-confirm-edit v-if="addPropertyEdit" class="mt-5" ok-text="Ok" cancel-text="Cancel" @cancel="cancelAddProperty" @save="addProperty" v-model="addPropertyModel" density="compact">
                            <template v-slot:default="{ model: proxyModel, actions }">
                                <v-card class="mx-auto" title="Add property" density="compact">
                                    <template v-slot:text>
                                        <v-text-field v-model="proxyModel.value" messages="Name" density="compact"></v-text-field>
                                    </template>
                                    <template v-slot:actions>
                                        <v-spacer></v-spacer>
                                        <component :is="actions"></component>
                                    </template>
                                </v-card>
                            </template>
                        </v-confirm-edit>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn icon="mdi-plus" @click="addPropertyEdit = true" text="Add Property"></v-btn>
                    </v-card-actions>
                </v-card>
            </v-navigation-drawer>
            <v-main>
                <v-container fluid class="fill-height" style="position: relative;">
                    <div ref="canvasDiv" id="mainCanvasDivId" class="viewport">
                        <canvas ref="canvasRef"></canvas>
                    </div>
                </v-container>
                <v-dialog v-model="settingsDialogVisible" max-width="600">
                    <v-card>
                        <v-card-title>
                            <span class="text-h6">Settings</span>
                        </v-card-title>
                        <v-card-text>
                            <v-checkbox label="Cameras visible" v-model="camerasVisible" @change="toggleCamerasVisibility" density="compact"></v-checkbox>
                            <v-checkbox label="Trajectory visible" v-model="trajectoryVisible" @change="toggleTrajectoryVisibility" density="compact"></v-checkbox>
                            <v-slider label="Line width" v-model="lineWidthModel" min="0.0007" max="0.07" @update:modelValue="changeLineWidthModel" density="compact"></v-slider>
                            <v-slider label="Light" v-model="lightValue" min="0.0" :max="lightValueMax" @update:modelValue="changeLightValue" density="compact"></v-slider>
                        </v-card-text>
                    </v-card>
                </v-dialog>


                <div v-if="floatingWindowVisible" class="floating-window" :style="windowStyles" @mousedown="onFloatWindowMouseDown">
                    <div class="floating-window-header" @mousedown.stop="onFloatWindowDragStart">
                        <span>{{viewer.imageLabel}}</span>
                        <v-btn icon="mdi-close" @click="floatingWindowToggle" density="compact" slim size="x-small" flat rounded="0"></v-btn>
                    </div>
                    <div ref="imageCanvasDivRef" class="viewport">
                        <canvas ref="imageCanvasRef"></canvas>
                    </div>
                    <div class="resize-handle top-left" @mousedown.stop.prevent="onFloatWindowResizeStart('top-left')"></div>
                    <div class="resize-handle top-right" @mousedown.stop.prevent="onFloatWindowResizeStart('top-right')"></div>
                    <div class="resize-handle bottom-left" @mousedown.stop.prevent="onFloatWindowResizeStart('bottom-left')"></div>
                    <div class="resize-handle bottom-right" @mousedown.stop.prevent="onFloatWindowResizeStart('bottom-right')"></div>
                </div>
                <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
                    {{ snackbarTextComputed() }}
                </v-snackbar>
            </v-main>
        </v-app>
        <input type="file" ref="fileInput" @change="importFiles" multiple style="display: none" />
        <svg style="display: none;">
            <symbol id="mdi-map-marker" viewBox="0 0 24 24"> <path fill="white" d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7" /> </symbol>
        </svg>
</template>

<script setup lang="ts">
import { computed, defineExpose, onBeforeMount, onMounted, onUnmounted, nextTick, defineComponent, useTemplateRef, ref, shallowRef, watch, reactive } from 'vue';
import { useCurrentUser, useFirestore, useFirebaseAuth } from 'vuefire';
import { useRoute } from 'vue-router';
import * as THREE from 'three';
import { createViewer, getViewer, destroyViewer } from '/components/ttViewer';
import { onProfile, onLogout } from '/components/ttUtils';
import * as ttCmd from '/components/ttCommands';
import { lineMaterialModel, lineMaterialImage } from '/components/ttMaterials';


const route = useRoute();
const user = useCurrentUser();
const auth = useFirebaseAuth();
const email = auth?.currentUser?.email;
const db = useFirestore();
let viewer : Viewer | null = null;
let isRunning = false;

const canvasDiv = useTemplateRef<HTMLElement | null>("canvasDiv");
const canvasRef = useTemplateRef<HTMLCanvasElement | null>("canvasRef");
const renderer = ref<THREE.WebGLRenderer | null>(null);
const imageCanvasDivRef = useTemplateRef<HTMLElement | null>("imageCanvasDivRef");
const imageCanvasRef = useTemplateRef<HTMLCanvasElement | null>("imageCanvasRef");
const imageRenderer = ref<THREE.WebGLRenderer | null>(null);
const fileInput = useTemplateRef<HTMLInputElement | null>(null);

const isResizingViewports = ref<bool>(false);
const leftWidth = ref(50);
const tabs = ref(null);

let resizeObserver = null;

onBeforeMount(() => {
    createViewer(user, route.params.projectName);
    viewer = getViewer();
});

watch(() => route.params.projectName, newProjectName => {
    console.log(" watch(() => route.params.projectName, newProjectName => {");
    createViewer(user, newProjectName);
}, { deep: true });

onMounted(async () => {
    await nextTick();
    window.addEventListener("resize", doWindowResize);
    if (canvasRef.value) {
        renderer.value = new THREE.WebGLRenderer({
            logarithmicDepthBuffer: true,
            //depth: false,
            canvas: canvasRef.value,
            antialias: true,
        })
        //renderer.value.shadowMap.enabled = true;
        //renderer.value.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.value.autoClear = false;
        renderer.value.setSize(canvasRef.value.clientWidth, canvasRef.value.clientHeight, false);
        renderer.value.setPixelRatio(window.devicePixelRatio);
        renderer.value.setClearColor(0x333333, 1);
        viewer.setMainCanvas(canvasRef.value, renderer.value.value);
    }
    else {
        console.error("Invalid canvasRef.value");
    }

    await viewer.afterMount();
    lines.value = viewer.lines;
    selectedLines.value = viewer.selection.objects;
    trajectoryLength.value = viewer.trajectory.getLength(); 
    isRunning = true;
    document.addEventListener('click', handleGlobalClick);

    resizeObserver = new ResizeObserver(() => {
        if (animationFrameId !== null) {
            cancelAnimationFrame(animationFrameId);
        }
        nextTick(() => {
            handleResize();
            animate();
        });
    });
    resizeObserver.observe(canvasDiv.value);

    animate();
});

onUnmounted(async () => {
    isRunning = false;
    viewer.onUnmounted();
    lines.value = null;
    selectedLines.value = [];
    document.removeEventListener('click', handleGlobalClick);
    window.removeEventListener("resize", doWindowResize);
    document.removeEventListener('mousemove', resizeViewports)
    document.removeEventListener('mouseup', stopResizingViewports)

    document.removeEventListener('mousemove', onFloatingWindowDrag);
    document.removeEventListener('mouseup', onFloatingWindowDragEnd);
    document.removeEventListener('mousemove', onFloatingWindowResize);
    document.removeEventListener('mouseup', onFloatingWindowResizeEnd);
    viewer = null;

    if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null;
    }
    destroyViewer();
});

function handleGlobalClick(event) {
    if (searchVisible.value && event.target.nodeName === "CANVAS") {
        searchVisible.value = false;
        searchQuery.value = "";
        previousPanelClickIndex = -1;
    }
}

function handleResize() {
    if (!canvasDiv.value)
        return;
    const brect = canvasDiv.value.getBoundingClientRect();
    renderer.value!.setSize(brect.width, brect.height, false);
    viewer.camera.aspect = brect.width / brect.height;
    viewer.camera.updateProjectionMatrix();
    viewer.controls.update();
    console.log("handleResize");
    viewer.updateOnControlChange();
}

async function doWindowResize(event) {
    await nextTick();
    handleResize();
}

function startResizingViewports(event) {
    isResizingViewports.value = true;
    document.addEventListener('mousemove', resizeViewports)
    document.addEventListener('mouseup', stopResizingViewports)
}

async function resizeViewports(event) {
    await nextTick();
    if (isResizingViewports.value) {
        const containerWieth = document.querySelector('.v-container').offsetWidth
        const newLeftWidth = (event.clientX / containerWidth) * 100
        if (newLeftWidth >= 1 && newLeftWidth <= 99) {
            leftWidth.value = newLeftWidth
        }
    }
    handleResize();
}

function stopResizingViewports() {
    isResizingViewports.value = false
    document.removeEventListener('mousemove', resizeViewports)
    document.removeEventListener('mouseup', stopResizingViewports)
}

let animationFrameId = null;
function animate() {
    if (!isRunning)
        return;
    if (viewer) {
        animationFrameId = requestAnimationFrame(animate);
        renderer.value!.clear();
        if (viewer.controls)
            viewer.controls.update();
        renderer.value!.render(viewer.scene, viewer.camera);
        viewer.viewHelper.render(renderer.value);
        if (viewer.stats)
            viewer.stats.update();
    }
}

let imageAnimateFrameId = null;
function imageAnimate() {
    if (!isRunning) return;
    if (viewer) {
        imageAnimateFrameId = requestAnimationFrame(imageAnimate);
        imageRenderer.value!.clear();
        if (viewer.orthoControls)
            viewer.orthoControls.update();
        imageRenderer.value!.render(viewer.imageScene, viewer.orthoCamera);
    }
}

const variesStr = '*Varies*'
const createdBy = ref('');
const severityIndex = ref(0);
const lines = ref(null);
const selectedLines = ref([]);

watch(selectedLines, () => {
        if (selectedLines.value.length === 1) {
            severityRef.value = severityItems[selectedLines.value[0].severity];
        }
        else {
            severityRef.value = null;
        }
    }
    , { deep: true, }
);

function onChangeSeverity() {
    if (!viewer) return;
    if (severityRef.value) {
        for (const obj of selectedLines.value) {
            obj.severity = severityRef.value.value;
        }
    }
};

const severityItems = [
    { title: "Unknown", value: 0, },
    { title: "Low", value: 1, },
    { title: "Normal", value: 2, },
    { title: "High", value: 3, },
];
const severityRef = ref(severityItems[1]);

const onChangeCreatedBy = (e: Event) => {
    if (!viewer) return;
    for (const obj of viewer.selection.objects) {
        obj.createdBy = createdBy.value;
    }
};

let previousPanelClickIndex = -1;
function handlePropPanelClick(line, index: number, shift: boolean, control: boolean) {
    cancelAddProperty();
    if (!viewer) {
        return
    }
    if (shift) {
        if (previousPanelClickIndex === index) {
            viewer.selection.remove(line);
        }
        else {
            if (previousPanelClickIndex < 0) {
                viewer.selection.add(line);
                previousPanelClickIndex = index;
            }
            else {
                viewer.selection.addRange(previousPanelClickIndex, index);
            }
        }
    }
    else {
        if (viewer.selection.has(line)) {
            viewer.selection.remove(line);
        }
        else {
            if (control) {
                viewer.selection.add(line);
            }
            else {
                viewer.selection.clear();
                viewer.selection.add(line);
            }
        }
    }
    previousPanelClickIndex = index;
}

const settingsDialogVisible = ref(false);

function handlePropPanelZoomTo(line, index: number) {
    if (viewer) {
        viewer.zoomTo(line.obj);
    }
}

const addPropertyEdit = ref(false);
const addPropertyModel = shallowRef("");

function addProperty() {
    console.log(addPropertyModel.value);
    selectedLines.value[0].custom[addPropertyModel.value] = "";
    addPropertyModel.value = "";
    addPropertyEdit.value = false;
}

function cancelAddProperty() {
    addPropertyEdit.value = false;
    addPropertyModel.value = '';
}

const floatingWindowVisible = ref(false);
const floatingWinndowPos = reactive({ x: 400, y: 300 });
const floatingWinndowSize = reactive({ width: 600, height: 480 });
const isDragging = ref(false);
const isResizing = ref(false);
const resizeDirection = ref('');
const startMousePosition = reactive({ x: 0, y: 0 });
const startWindowSize = reactive({ width: 0, height: 0 });
const startWindowPosition = reactive({ x: 400, y: 300 });

const windowStyles = computed(() =>
({
    left: `${floatingWinndowPos.x}px`,
    top: `${floatingWinndowPos.y}px`,
    width: `${floatingWinndowSize.width}px`,
    height: `${floatingWinndowSize.height}px`,
}));

function updateImageWindowParams() {
    const rect = imageCanvasDivRef.value.getBoundingClientRect();
    imageRenderer.value!.setSize(rect.width, rect.height, true);
    if (viewer && viewer.metashapeData) {
        const imwidth = viewer.metashapeData.sensors[0].widthPx;
        const imheight = viewer.metashapeData.sensors[0].heightPx;
        const aspect = rect.width / rect.height;
        viewer.orthoCamera.left   = 0.0;
        viewer.orthoCamera.right  = viewer.metashapeData.sensors[0].widthPx;
        viewer.orthoCamera.top    = viewer.metashapeData.sensors[0].widthPx / aspect;
        viewer.orthoCamera.bottom = 0.0;
    }
    viewer.orthoControls.update();
    viewer.orthoCamera.updateProjectionMatrix();
    console.log(viewer.orthoControls.target);
    console.log(viewer.orthoCamera.position);
    console.log(viewer.orthoCamera.left,
        viewer.orthoCamera.right,
        viewer.orthoCamera.top,
        viewer.orthoCamera.bottom,
        " zoom: ",
        viewer.orthoCamera.zoom
     );
     console.log("---");
}

async function floatingWindowToggle() {
    floatingWindowVisible.value = !floatingWindowVisible.value;
    if (floatingWindowVisible.value) {
        await nextTick();
        const imageCanvas = imageCanvasRef.value;
        if (imageCanvas && viewer) {
            imageRenderer.value = new THREE.WebGLRenderer({
                logarithmicDepthBuffer: false,
                depth: false,
                canvas: imageCanvas,
                antialias: true,
            })
            imageRenderer.value.autoClear = false;
            imageRenderer.value.setSize(imageCanvas.clientWidth, imageCanvas.clientHeight, true);
            imageRenderer.value.setPixelRatio(window.devicePixelRatio);
            imageRenderer.value.setClearColor(0x333333, 1);
            viewer.setImageCanvas(imageCanvasRef.value, imageRenderer.value);
            imageAnimate();
        }
        else {
            console.error("Invalid imageCanvasRef.value");
        }
    }
    else {
        cancelAnimationFrame(imageAnimateFrameId);
        imageAnimateFrameId = null;
    }
}

function onFloatWindowMouseDown(event) {
    event.preventDefault();
}

function onFloatWindowDragStart(event) {
    isDragging.value = true;
    startMousePosition.x = event.clientX;
    startMousePosition.y = event.clientY;
    startWindowPosition.x = floatingWinndowPos.x;
    startWindowPosition.y = floatingWinndowPos.y;
    document.addEventListener('mousemove', onFloatingWindowDrag);
    document.addEventListener('mouseup', onFloatingWindowDragEnd);
}

function onFloatingWindowDrag(event) {
    if (!isDragging.value) return;
    const dx = event.clientX - startMousePosition.x;
    const dy = event.clientY - startMousePosition.y;
    floatingWinndowPos.x = startWindowPosition.x + dx;
    floatingWinndowPos.y = startWindowPosition.y + dy;
}

function onFloatingWindowDragEnd() {
    isDragging.value = false;
    document.removeEventListener('mousemove', onFloatingWindowDrag);
    document.removeEventListener('mouseup', onFloatingWindowDragEnd);
}

function onFloatWindowResizeStart(direction) {
    isResizing.value = true;
    resizeDirection.value = direction;
    startMousePosition.x = event.clientX;
    startMousePosition.y = event.clientY;
    startWindowSize.width = floatingWinndowSize.width;
    startWindowSize.height = floatingWinndowSize.height;
    startWindowPosition.x = floatingWinndowPos.x;
    startWindowPosition.y = floatingWinndowPos.y;
    document.addEventListener('mousemove', onFloatingWindowResize);
    document.addEventListener('mouseup', onFloatingWindowResizeEnd);
}

function onFloatingWindowResize(event) {
    if (!isResizing.value) return;
    const dx = event.clientX - startMousePosition.x;
    const dy = event.clientY - startMousePosition.y;

    switch (resizeDirection.value) {
        case 'top-left':
            floatingWinndowSize.width = startWindowSize.width - dx;
            floatingWinndowSize.height = startWindowSize.height - dy;
            floatingWinndowPos.x = startWindowPosition.x + dx;
            floatingWinndowPos.y = startWindowPosition.y + dy;
            break;
        case 'top-right':
            floatingWinndowSize.width = startWindowSize.width + dx;
            floatingWinndowSize.height = startWindowSize.height - dy;
            floatingWinndowPos.y = startWindowPosition.y + dy;
            break;
        case 'bottom-left':
            floatingWinndowSize.width = startWindowSize.width - dx;
            floatingWinndowSize.height = startWindowSize.height + dy;
            floatingWinndowPos.x = startWindowPosition.x + dx;
            break;
        case 'bottom-right':
            floatingWinndowSize.width = startWindowSize.width + dx;
            floatingWinndowSize.height = startWindowSize.height + dy;
            break;
    }
}

function onFloatingWindowResizeEnd() {
    isResizing.value = false;
    resizeDirection.value = '';
    document.removeEventListener('mousemove', onFloatingWindowResize);
    document.removeEventListener('mouseup', onFloatingWindowResizeEnd);
    updateImageWindowParams();
}

function undo() {
    if (viewer) {
        viewer.undo();
    }
}

function redo() {
    if (viewer) {
        viewer.redo();
    }
}

function createPolyline() {
    if (viewer) {
        viewer.createPolylineCommand();
    }
}

function createMarker() {
    if (viewer) {
        viewer.createMarkerCommand();
    }
}

function getDistance() {
    if (viewer) {
        viewer.getDistanceCommand();
        const fn = () => {
            if (viewer.currentCommand.value && viewer.currentCommand.value instanceof ttCmd.GetDistanceCmd) {
                return `Length: ${viewer.currentCommand.value.length}`;
            }
            else {
                snackbarText.value = "";
                snackbar.value = false;
                return "";
            }
        };
        showSnackbar(fn, -1);
    }
}

function acceptCommand() {
    if (viewer) {
        viewer.endCommand();
    }
}

function cancelCommand() {
    if (viewer) {
        viewer.cancelCommand();
    }
}

function deleteSelection() {
    if (viewer && viewer.currentCommand.value === null) {
        let command = new ttCmd.DeleteLinesCmd(viewer);
        viewer.beginCommand(command);
        viewer.endCommand();
    }
}

async function onSave() {
    if (viewer) {
        await viewer.saveServer();
        showSnackbar("Data saved sucessfully");
    }
}

function openFilePicker() {
    fileInput.value?.click();
}

async function importFiles(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) {
        return;
    }
    const files = Array.from(target.files);
    try {
        const importedData = await Promise.all(files.map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        try {
                            const json = JSON.parse(e.target?.result);
                            resolve(json);
                        }
                        catch (error) {
                            reject(new Error(`Invalid JSON in file ${file.name}`));
                        }
                    }
                    reader.onerror = (e) => reject(new Error(`Error reading file ${file.name}: ${e}`));
                    reader.readAsText(file);
                });
        }));

        for (const fileData of importedData) {
            viewer.importJSON(fileData);
        }

        showSnackbar(`Successfully imported ${importedData.length} file(s)`);
    }
    catch (error) {
        showSnackbar(`Error loading files: ` + files.map((file) => file.name) + `\nError: ${error}`);
    }
}

const camerasVisible = ref(false);
function toggleCamerasVisibility() {
    if (viewer) {
        let cmd = new ttCmd.ToggleCamerasVisibilityCmd(viewer, camerasVisible.value);
        viewer.beginCommand(cmd);
        viewer.endCommand();
    }
}

const trajectoryVisible = ref(true);
function toggleTrajectoryVisibility() {
    if (viewer) {
        let cmd = new ttCmd.ToggleTrajectoryVisibilityCmd(viewer, trajectoryVisible.value);
        viewer.beginCommand(cmd);
        viewer.endCommand();
    }
}

const lineWidthModel = ref<number>(0.014);
function changeLineWidthModel() {
    if (viewer) {
        for (const lm of lineMaterialModel)
            lm.linewidth = lineWidthModel.value;
    }
}

const trajectoryPosition = computed({
    get() {
        if (viewer) {
            return viewer.trajectoryPos.value.w;
        }
        return 0.0;
    },
    set(value) {
        if (viewer) {
            viewer.setStation(value);
        }
    }

});
const trajectoryLength = ref<number>(1.0);

const lightValue = ref<number>(1.0);
const lightValueMax = 10.0;
function changeLightValue() {
    if (viewer) {
        const ambientLight : THREE.AmbientLight = viewer.scene.getObjectByName("ambient_light");
        if (ambientLight)
            ambientLight.intensity = lightValue.value;
    }
}

const searchVisible = ref(false);
const searchQuery = ref('');
const searchInput = ref(null);

const filteredLines = computed(() => {
    if (!searchQuery.value) {
        return lines.value;
    }
    return filterObjects(lines.value, searchQuery.value);
});

function toggleSearch() {
    searchVisible.value = !searchVisible.value;
    if (searchVisible.value) {
        // Focus the input field after it becomes visible
        nextTick(() => {
            searchInput.value.focus();
        });
    } else {
        searchQuery.value = '';
    }
}

function filterObjects(items, query: string) {
    if (!query)
        return items;
    query = query.trim().toLocaleUpperCase();
    if (query.length === 0)
        return items;
    const eqindex = query.indexOf("=");
    if (eqindex === -1) {
        return items.filter(item => {
            if (item.name.toLocaleUpperCase().includes(query))
                return true;
            return Object.entries(item.custom).some(([prop, value]) => {
                if (prop.toLocaleUpperCase().includes(query))
                    return true;
                if (String(value).toLocaleUpperCase().includes(query))
                    return true;
                return false;
            });
        });
    }
    else {
        const propq = query.substring(0, eqindex).trim().toLocaleUpperCase();
        const valueq = query.substring(eqindex + 1, query.length).trim().toLocaleUpperCase();
        if (propq === "SEVERITY") {
        }
        return items.filter(item => {
            if (propq === "NAME") {
                if (item.name.toLocaleUpperCase().includes(valueq))
                    return true;
                return false;
            }
            else if (propq === "SEVERITY") {
                if (valueq === "LOW")
                    return item.severity === 1;
                else if (valueq === "NORMAL" || valueq === "MEDIUM")
                    return item.severity === 2;
                else if (valueq === "HIGH")
                    return item.severity === 3;
                return false;
            }
            return Object.entries(item.custom).some(([prop, value]) => {
                if (prop.toLocaleUpperCase() === propq) {
                    return String(value).toLocaleUpperCase().includes(valueq);
                }
                return false;
            });
        });
    }
}

function openUrl() {
    if (selectedLines.value[0].url.includes("://")) {
        window.open(selectedLines.value[0].url);
    }
    else {
        window.open("http://" + selectedLines.value[0].url);
    }
}

const snackbar = ref(false);
const snackbarText = ref("");
let snackbarTimeout = 3000;
function showSnackbar(message, timeout = 3000) {
    snackbarText.value = message;
    snackbar.value = true;
    snackbarTimeout = timeout;
}
const snackbarTextComputed = () => {
    if (typeof snackbarText.value === "string") {
        return snackbarText.value;
    }
    else {
        return snackbarText.value();
    }
}

</script>

<style scoped>
html, body, #app {
    margin: 0;
    padding: 0;
    height: 100%;
}

.fill-height {
    margin: 0;
    padding: 0;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}

.viewport {
    padding: 0;
    height: 100%;
    width: 100%;
}

canvas {
    width: 100%;
    height: 100%;
}

.divider {
    width: 40px;
    background-color: lightgray;
    transition: background-color 0.3s ease;
}

.divider:hover {
    background-color: black;
}

.floating-window {
    position: absolute;
    border: 1px solid #ccc;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.floating-window-header {
    margin: 5px;
    cursor: move;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.resize-handle {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: transparent;
}

.resize-handle.top-left {
    top: -7px;
    left: -7px;
    cursor: nwse-resize;
}

.resize-handle.top-right {
    top: -7px;
    right: -7px;
    cursor: nesw-resize;
}

.resize-handle.bottom-left {
    bottom: -7px;
    left: -7px;
    cursor: nesw-resize;
}

.resize-handle.bottom-right {
    bottom: -7px;
    right: -7px;
    cursor: nwse-resize;
}

.search-container {
    display: flex;
    align-items: center;
    width: 240px; /* Adjust this width as needed */
    justify-content: flex-end;
    margin-right: 8px; /* Add some margin to separate from other buttons */
}

</style>
