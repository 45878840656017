import { Viewer, Selection } from './ttViewer';
import { Polyline } from './ttObject';
import { lineMaterialModel, lineMaterialImage } from './ttMaterials';
import * as ttUtils from './ttUtils';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

import { useCurrentUser, useFirebaseAuth } from 'vuefire';
import { ref } from 'vue';

import * as THREE from 'three';

export abstract class CmdBase {
    viewer: Viewer;
    constructor(viewer: Viewer) {
        this.viewer = viewer;
    }

    onBegin() { }

    onEnd() { }

    onCancel() { }

    longCommand() { return true; }

    undo() {};
    redo() {};
}

const imageAHelper = new THREE.AxesHelper(50);
const mainAHelper = new THREE.AxesHelper(0.05);

export class CreatePolylineCmd extends CmdBase {
    line: Polyline = new Polyline(null);
    points: number[] = [];
    canvas: HTMLElement;
    imageCanvas: HTMLElement | null;
    controls: OrbitControls;
    imageControls: OrbitControls | undefined;

    geometry: LineGeometry = new LineGeometry();
    imgeometry: LineGeometry | null = null;
    imageLine: LineSegments2 = new LineSegments2;

    constructor(viewer: Viewer) {
        super(viewer);
        const email = useCurrentUser().value?.email;
        if (email)
            this.line.createdBy = email;
        this.line.setMaterial(this.viewer.currentSeverity);
        this.imageLine.material = lineMaterialImage[this.viewer.currentSeverity];
        this.imageLine.renderOrder = 1;
        viewer.addLine(this.line);
        if (!viewer.canvas || !viewer.controls)
            throw new Error("viewer is not correctly initialized yet!");
        this.canvas        = viewer.canvas;
        this.imageCanvas   = viewer.imageCanvas;
        this.controls      = viewer.controls;
        this.imageControls = viewer.orthoControls;
    }

    appendPoint(point: THREE.Vector3) {
        this.points.push(point.x);
        this.points.push(point.y);
        this.points.push(point.z);
        if (this.points.length === 3) {
            this.points.push(point.x);
            this.points.push(point.y);
            this.points.push(point.z);
        }
        this.regenerateLines();
    }

    regenerateLines() {
        this.geometry.setPositions(this.points);
        this.line.obj.geometry.dispose();
        this.line.obj.geometry = this.geometry;
        this.line.obj.computeLineDistances();

        if (this.viewer.metashapeData && this.viewer.cameraIndex >= 0) {
            const camera = this.viewer.metashapeData.cameras[this.viewer.cameraIndex];
            const tfx = new THREE.Matrix4();
            tfx.copy(this.viewer.metashapeData.transform);
            tfx.multiply(camera.transform);
            tfx.invert();
            const sensor = this.viewer.metashapeData.sensors.find(e => e.id === camera.sensorId);
            if (sensor) {
                this.imgeometry = ttUtils.projectLineOnImage(this.line, tfx, sensor, false);
                if (this.imgeometry) {
                    this.imageLine.geometry.dispose();
                    this.imageLine.geometry = this.imgeometry;
                    this.imageLine.computeLineDistances();
                }
            }
        }
    }

    setEndPoint(point: THREE.Vector3) {
        if (this.points.length >= 3) {
            this.points[this.points.length - 3] = point.x;
            this.points[this.points.length - 2] = point.y;
            this.points[this.points.length - 1] = point.z;
            this.regenerateLines();
        }
    }

    mainCanvasMoveHandler = (event: MouseEvent) => {
        if (this.points.length === 0)
            return;
        const intersection = ttUtils.raycastPointOnMainModel(this.viewer, event);
        if (intersection === null)
            return;
        this.setEndPoint(intersection.point);
    }

    mainCanvasClickHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnMainModel(this.viewer, event);
        if (intersection === null)
            return;
        this.appendPoint(intersection.point);
    }

    imageCanvasMoveHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnImage(this.viewer, event);
        if (!intersection)
            return;
        const point = intersection.point;
        point.z = -2;
        imageAHelper.position.copy(point);
        const modelIntersection = ttUtils.unproject(this.viewer, point.x, point.y);
        if (modelIntersection) {
            mainAHelper.position.copy(modelIntersection);
            this.setEndPoint(modelIntersection);
        }
    }

    imageCanvasClickHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnImage(this.viewer, event);
        if (!intersection)
            return;
        const modelIntersection = ttUtils.unproject(this.viewer, intersection.point.x, intersection.point.y);
        if (modelIntersection) {
            this.appendPoint(modelIntersection);
        }
    }

    onBegin() {
        super.onBegin();
        this.controls.enableRotate = false;
        this.canvas.addEventListener("mousemove", this.mainCanvasMoveHandler);
        this.canvas.addEventListener("click", this.mainCanvasClickHandler);
        if (this.imageCanvas) {
            this.imageCanvas.addEventListener("mousemove", this.imageCanvasMoveHandler);
            this.imageCanvas.addEventListener("click", this.imageCanvasClickHandler);
        }

        this.viewer.imageScene.add(imageAHelper);
        this.viewer.scene.add(mainAHelper);
        this.viewer.imageScene.add(this.imageLine);
        this.viewer.drawnLinesImage.push(this.imageLine);
    }

    onEnd() {
        super.onEnd();
        this.controls.enableRotate = true;
        this.canvas.removeEventListener("mousemove", this.mainCanvasMoveHandler);
        this.canvas.removeEventListener("click", this.mainCanvasClickHandler);
        if (this.imageCanvas) {
            this.imageCanvas.removeEventListener("mousemove", this.imageCanvasMoveHandler);
            this.imageCanvas.removeEventListener("click", this.imageCanvasClickHandler);
        }

        this.points.length = this.points.length - 3;
        if (this.points.length === 0) {
            this.viewer.removeLine(this.line);
        }
        else {
            const geometry = new LineGeometry();
            geometry.setPositions(this.points);
            this.line.obj.geometry.dispose();
            this.line.obj.geometry = geometry;
            this.line.obj.computeLineDistances();
        }

        this.viewer.imageScene.remove(imageAHelper);
        this.viewer.scene.remove(mainAHelper);
    }

    onCancel() {
        super.onCancel();
        this.viewer.removeLine(this.line);
        this.controls.enableRotate = true;
        this.canvas.removeEventListener("mousemove", this.mainCanvasMoveHandler);
        this.canvas.removeEventListener("click", this.mainCanvasClickHandler);
        if (this.imageCanvas) {
            this.imageCanvas.removeEventListener("mousemove", this.imageCanvasMoveHandler);
            this.imageCanvas.removeEventListener("click", this.imageCanvasClickHandler);
        }

        this.viewer.imageScene.remove(imageAHelper);
        this.viewer.scene.remove(mainAHelper);
        this.viewer.imageScene.remove(this.imageLine);
        this.viewer.drawnLinesImage.pop();
    }

    undo() {
        this.viewer.removeLine(this.line);
    }

    redo() {
        this.viewer.addLine(this.line);
    }
}

export class CreateMarkerCommand extends CmdBase {
    canvas: HTMLElement;
    controls: OrbitControls;
    line: Polyline = new Polyline(null);

    constructor(viewer: Viewer) {
        super(viewer);
        if (!viewer.canvas || !viewer.controls)
            throw new Error("viewer is not correctly initialized yet!");
        this.canvas        = viewer.canvas;
        this.controls      = viewer.controls;
    }

    mainCanvasClickHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnMainModel(this.viewer, event);
        if (intersection === null)
            return;
        const geometry = new LineGeometry();
        geometry.setPositions(intersection.point.toArray().concat( intersection.point.toArray()));
        this.line.obj.geometry.dispose();
        this.line.obj.geometry = geometry;
        this.line.obj.computeLineDistances();
        this.line.name = "Marker - " + this.line.obj.id;
        this.line.setSeverity(0);
        this.viewer.addLine(this.line);
        this.viewer.endCommand();
    }

    onBegin() {
        super.onBegin();
        this.canvas.addEventListener("click", this.mainCanvasClickHandler);
    }

    onEnd() {
        super.onEnd();
        this.canvas.removeEventListener("click", this.mainCanvasClickHandler);
        this.viewer.selection.clear();
        this.viewer.selection.add(this.viewer.lines[this.viewer.lines.length - 1]);
    }

    onCancel() {
        super.onCancel();
    }

    undo() {
        this.viewer.removeLine(this.line);
    }

    redo() {
        this.viewer.addLine(this.line);
    }
}

export class GetDistanceCmd extends CmdBase {
    sp: THREE.Vector3 = new THREE.Vector3();
    ep: THREE.Vector3 = new THREE.Vector3();
    points: number[] = [];
    canvas: HTMLElement;
    imageCanvas: HTMLElement | null;
    controls: OrbitControls;
    imageControls: OrbitControls | undefined;

    line: LineSegments2 = new LineSegments2();
    imageLine: LineSegments2 = new LineSegments2;
    geometry: LineGeometry = new LineGeometry();
    length = ref<number>(0.0);

    constructor(viewer: Viewer) {
        super(viewer);
        if (!viewer.canvas || !viewer.controls)
            throw new Error("viewer is not correctly initialized yet!");
        this.canvas        = viewer.canvas;
        this.imageCanvas   = viewer.imageCanvas;
        this.controls      = viewer.controls;
        this.imageControls = viewer.orthoControls;
        this.line.material = lineMaterialModel[0];
    }

    setStartPoint(point: THREE.Vector3) {
        this.sp = point;
        if (this.points.length === 0) {
            this.points.push(point.x);
            this.points.push(point.y);
            this.points.push(point.z);
        }
        else {
            this.points[0] = point.x;
            this.points[1] = point.y;
            this.points[2] = point.z;
        }
        this.geometry.setPositions(this.points);
        this.line.geometry.dispose();
        this.line.geometry = this.geometry;
        this.line.computeLineDistances();
    }

    setEndPoint(point: THREE.Vector3) {
        this.ep = point;
        if (this.points.length < 6) {
            this.points.push(point.x);
            this.points.push(point.y);
            this.points.push(point.z);
        }
        else {
            this.points[3] = point.x;
            this.points[4] = point.y;
            this.points[5] = point.z;
        }
        this.length.value = this.sp.distanceTo(this.ep);
        this.geometry.setPositions(this.points);
        this.line.geometry.dispose();
        this.line.geometry = this.geometry;
        this.line.computeLineDistances();
    }

    collectPoint(pointOnModel: THREE.Vector3) {
        if (this.points.length === 0) {
            this.setStartPoint(pointOnModel);
        }
        else {
            this.viewer.cancelCommand();
        }
    }

    mainCanvasClickHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnMainModel(this.viewer, event);
        if (intersection === null)
            return;
        this.collectPoint(intersection.point);
    }

    mainCanvasMoveHandler = (event: MouseEvent) => {
        if (this.points.length === 0)
            return;
        const intersection = ttUtils.raycastPointOnMainModel(this.viewer, event);
        if (intersection === null)
            return;
        this.setEndPoint(intersection.point);
    }

    imageCanvasMoveHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnImage(this.viewer, event);
        if (!intersection)
            return;
        const point = intersection.point;
        point.z = -2;
        imageAHelper.position.copy(point);
        const modelIntersection = ttUtils.unproject(this.viewer, point.x, point.y);
        if (modelIntersection) {
            mainAHelper.position.copy(modelIntersection);
            if (this.points.length > 0) {
                this.setEndPoint(modelIntersection);
            }
        }
    }

    imageCanvasClickHandler = (event: MouseEvent) => {
        const intersection = ttUtils.raycastPointOnImage(this.viewer, event);
        if (!intersection)
            return;
        const modelIntersection = ttUtils.unproject(this.viewer, intersection.point.x, intersection.point.y);
        if (modelIntersection) {
            this.collectPoint(modelIntersection);
        }
    }

    onBegin() {
        super.onBegin();
        this.canvas.addEventListener("mousemove", this.mainCanvasMoveHandler);
        this.canvas.addEventListener("click", this.mainCanvasClickHandler);
        if (this.imageCanvas) {
            this.imageCanvas.addEventListener("mousemove", this.imageCanvasMoveHandler);
            this.imageCanvas.addEventListener("click", this.imageCanvasClickHandler);
        }

        this.viewer.imageScene.add(imageAHelper);
        this.viewer.scene.add(this.line);
        this.viewer.scene.add(mainAHelper);
        this.viewer.imageScene.add(this.imageLine);
        this.viewer.drawnLinesImage.push(this.imageLine);
    }

    onEnd() {
        super.onEnd();
        this.canvas.removeEventListener("mousemove", this.mainCanvasMoveHandler);
        this.canvas.removeEventListener("click", this.mainCanvasClickHandler);
        if (this.imageCanvas) {
            this.imageCanvas.removeEventListener("mousemove", this.imageCanvasMoveHandler);
            this.imageCanvas.removeEventListener("click", this.imageCanvasClickHandler);
        }

        this.viewer.scene.remove(mainAHelper);
        this.viewer.scene.remove(this.line);
        this.viewer.imageScene.remove(imageAHelper);
        this.viewer.imageScene.remove(this.imageLine);
        this.viewer.drawnLinesImage.pop();
    }

    onCancel() {
        super.onCancel();
        this.onEnd();
    }

    undo() { }

    redo() { }
}

export class DeleteLinesCmd extends CmdBase {
    selection: Selection;
    constructor(viewer: Viewer) {
        super(viewer);
        this.selection = viewer.selection.clone();
    }

    onBegin() {
        super.onBegin();
        for (const obj of this.selection.objects) {
            this.viewer.removeLine(obj);
            this.viewer.selection.remove(obj);
        }
    }

    undo() {
        for (const obj of this.selection.objects) {
            this.viewer.addLine(obj);
            this.viewer.selection.add(obj);
        }
    }

    redo() {
        this.onBegin();
    }

    longCommand(): boolean {
        return false;
    }
}

export class ToggleCamerasVisibilityCmd extends CmdBase {
    value: boolean;
    constructor(viewer: Viewer, value: boolean) {
        super(viewer);
        this.value = value;
    }

    onBegin() {
        super.onBegin();
        this.viewer.settings.set('cameras_visible', this.value);
        for (let camera of this.viewer.msCameras) {
            camera.visible = this.value;
        }
    }

    undo() {
        this.viewer.settings.set('cameras_visible', !this.value);
        for (let camera of this.viewer.msCameras) {
            camera.visible = !this.value;
        }
    }

    redo() {
        this.viewer.settings.set('cameras_visible', this.value);
        for (let camera of this.viewer.msCameras) {
            camera.visible = this.value;
        }
    }

    longCommand(): boolean {
        return false;
    }
}

export class ToggleTrajectoryVisibilityCmd extends CmdBase {
    value: boolean;
    constructor(viewer: Viewer, value: boolean) {
        super(viewer);
        this.value = value;
    }

    onBegin() {
        super.onBegin();
        this.viewer.settings.set('trajectory_visible', this.value);
        const trajectory = this.viewer.scene.getObjectByName("trajectory");
        if (trajectory) {
            trajectory.visible = this.value;
        }
    }

    undo() {
        this.viewer.settings.set('trajectory_visible', !this.value);
        const trajectory = this.viewer.scene.getObjectByName("trajectory");
        if (trajectory) {
            trajectory.visible = !this.value;
        }
    }

    redo() {
        this.viewer.settings.set('trajectory_visible', this.value);
        const trajectory = this.viewer.scene.getObjectByName("trajectory");
        if (trajectory) {
            trajectory.visible = this.value;
        }
    }

    longCommand(): boolean {
        return false;
    }
}


// {
//     data: [
//         {
//             image_name: "ASDF123.JPG",
//             lines: [
//                 {
//                     data: [
//                         120, 50,
//                         100, 55,
//                         90, 57,
//                     ],
//                     meta: {
//                         crack_type: "",
//                     }
//                 },
//                 {
//                     data: [.....]
//                     ....
//                 }
//                 {
//                 }
//             ],
//         }
//         {
//             image_name: "B1234.JPG",
//             ...
//         }
//     ],
// }
