import * as THREE from 'three';
import { markRaw } from 'vue';
import { lineMaterialModel, lineMaterialImage } from './ttMaterials';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { getViewer } from './ttViewer';
import * as ttUtils from './ttUtils'

export class ObjectBase<Type extends THREE.Object3D> {
    obj: Type;
    url: string = "";
    custom: Object = {};
    name: string = "";
    constructor(obj: Type) {
        this.obj = obj;
        markRaw(obj);
    }
}

export class Camera extends ObjectBase<THREE.Mesh> {
    sensorIdx: number = 0;
    componentIdx: number = 0;
    transform: THREE.Matrix4 = new THREE.Matrix4();
}

export class Polyline extends ObjectBase<LineSegments2> {
    createdBy: string = 'Unknown';
    severity: number = 1;
    svg: object | null = null;

    constructor(points: number[] | null) {
        super(new LineSegments2());
        this.setMaterial(this.severity);
        if (points) {
            const geometry = new LineGeometry();
            geometry.setPositions(points);
            this.obj.geometry = geometry;
            this.obj.computeLineDistances();
        }
    }

    count(): number {
        let geometry: THREE.BufferGeometry = this.obj.geometry;
        const instanceStart = geometry.attributes.instanceStart;
        if (!instanceStart)
            return 0;
        return instanceStart.count + 1;
    }

    vertexAt(index: number, vertex: THREE.Vector3) {
        let geometry: THREE.BufferGeometry = this.obj.geometry;
        const instanceStart = geometry.attributes.instanceStart;
        const instanceEnd = geometry.attributes.instanceEnd;
        if (instanceStart === null || instanceEnd === null)
            return;
        if (index < instanceStart.count)
            vertex.fromBufferAttribute(instanceStart, index);
        else
            vertex.fromBufferAttribute(instanceEnd, index - 1);
    }

    toJSON(): object {
        let geometry: THREE.BufferGeometry = this.obj.geometry;
        const instanceStart = geometry.attributes.instanceStart;
        const instanceEnd = geometry.attributes.instanceEnd;
        if (instanceStart === null || instanceEnd === null)
            return {};
        const count = Math.min(geometry.instanceCount, instanceStart.count);
        const vertex = new THREE.Vector3();
        let data: number[] = [];
        for (let i = 0; i < count; i++) {
            vertex.fromBufferAttribute(instanceStart, i);
            data.push(...vertex.toArray());
        }
        vertex.fromBufferAttribute(instanceEnd, count - 1);
        data.push(...vertex.toArray());

        const meta = {
            ver: 1,
            uuid: this.obj.uuid,
            createdBy: this.createdBy,
            severity: this.severity,
            name: this.name,
            custom: this.custom,
            url: this.url,
        }

        return { data, meta };
    }

    async fromJSON(json: object) {
        const geometry = new LineGeometry();
        geometry.setPositions(json.data);
        this.obj.geometry = geometry;
        this.createdBy = json.meta.createdBy;
        if ('severity' in json.meta)
            this.severity = json.meta.severity;
        this.obj.uuid = json.meta.uuid;
        this.setSeverity(this.severity);
        if (json.meta.name) {
            this.name = json.meta.name;
        }
        if (json.meta.custom)
            this.custom = json.meta.custom;
        if (json.meta.url)
            this.url = json.meta.url;
    }

    getColor(): number {
        return this.obj.material.color.toJSON();
    }

    setSeverity(value: number) {
        if (getViewer()?.selection.has(this))
            return;
        this.severity = value;
        this.setMaterial(value);
    }

    setMaterial(value: number) {
        this.obj.material = lineMaterialModel[value];
        if (this.svg) {
            this.svg.style.color = `#${this.getColor().toString(16).padStart(6, '0')}`;
            this.svg.style.textShadow = `0px 0px 1px ${ttUtils.contrastColor(this.getColor())}`;
        }
    }

    uuid(): string {
        return this.obj.uuid;
    }
}

